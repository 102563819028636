// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-astrophoto-astrophoto-js": () => import("./../../../src/components/templates/Astrophoto/astrophoto.js" /* webpackChunkName: "component---src-components-templates-astrophoto-astrophoto-js" */),
  "component---src-pages-404-ts": () => import("./../../../src/pages/404.ts" /* webpackChunkName: "component---src-pages-404-ts" */),
  "component---src-pages-about-ts": () => import("./../../../src/pages/about.ts" /* webpackChunkName: "component---src-pages-about-ts" */),
  "component---src-pages-contact-ts": () => import("./../../../src/pages/contact.ts" /* webpackChunkName: "component---src-pages-contact-ts" */),
  "component---src-pages-deep-sky-tsx": () => import("./../../../src/pages/deep-sky.tsx" /* webpackChunkName: "component---src-pages-deep-sky-tsx" */),
  "component---src-pages-equipment-ts": () => import("./../../../src/pages/equipment.ts" /* webpackChunkName: "component---src-pages-equipment-ts" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-publications-ts": () => import("./../../../src/pages/publications.ts" /* webpackChunkName: "component---src-pages-publications-ts" */),
  "component---src-pages-solar-system-tsx": () => import("./../../../src/pages/solar-system.tsx" /* webpackChunkName: "component---src-pages-solar-system-tsx" */),
  "component---src-pages-starscape-tsx": () => import("./../../../src/pages/starscape.tsx" /* webpackChunkName: "component---src-pages-starscape-tsx" */)
}

